<template>
  <!--Carousel Image-->
  <div>
    <!--kalkulator cerdas-->
    <b-container class="pt-4">
      <b-row class="p-4">
        <h3 class="m-auto">Kalkulator Cerdas</h3>
      </b-row>
      <b-row>
        <b-col
          v-for="calculator in calculators"
          :key="`calculator-${calculator.id}`"
        >
          <calculator-card
            :id="calculator.id"
            :label="calculator.label"
            :path="calculator.path"
            :logo="calculator.logo"
          ></calculator-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import CalculatorCard from '../components/CalculatorCard.vue'
import calculators from '../data/calculators.json'

export default {
  name: 'Kalkulator',
  components: {
    CalculatorCard,
  },
  data() {
    return {
      calculators: calculators,
    }
  },
}
</script>
